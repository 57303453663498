@import './Variables';

body {
  margin: 0;
  font-family:"ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
  color: $black;
}

img { display: inline-block; }

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: $aqua;
  }
}
.back-button {
  color: $aqua;
  &:hover {
    color: $light-aqua;
  }
}

hr {
  margin: 0;
}

header {
  text-align: center;
  margin-bottom: 20px;
  h1 {
    &:before {
      content: '';
      display: inline-block;
      background-image: url(../images/logo.png);
      width: 66px;
      height: 40px;
      background-size: 66px 40px;
      vertical-align: middle;
    }
    &:hover {
      &:before {
        background-image: url(../images/logo-hover.png);
      }
    }
  }
}

footer {
  text-align: center;
  padding: 30px 0;
  .forSP {
    display: none;
  }
  @media screen and (max-width: 454px) {
    .forSP {
      display: block;
    }
  }
}
