@import './Variables';

.index {
  text-align: center;
  &__chara-image {
    width: 258px;
    height: 240px;
    animation: 2s ease-out 1s index__chara-image--slidein;
  }
  &__chara-message {
    &-frame {
      display: inline-block;
      padding: 10px;
      border: $black 2px solid;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }

  &__work-list {
    &-h2 {
      margin: 10px;
    }
    &--work {
      $n: &;
      margin: 20px;
      img {
        width: 640px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  &__know-how,
  &__article {
    &-h2 {
      margin: 10px;
    }
    &--streaming,
    &--dev,
    &--game {
      $n: &;
      margin: 20px;
      img {
        width: 640px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

@keyframes index__chara-image--slidein {
  0% { margin-left: 10000px; }
  70% { margin-left: 0; transform: scaleX(1); }
  75% { margin-left: -100px; transform: scaleX(0.5); }
  80% { margin-left: 0; transform: scaleX(1); }
  90% { margin-left: -100px; transform: scaleX(0.5); }
  100% { margin-left: 0; transform: scaleX(1); }
}
