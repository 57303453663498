@import '../Variables';
.t {
  &-index {
    text-align: center;
    h2 { margin: 10px 0; }
    ul {
      list-style: none;
      li {
        a {
          &:hover {
            opacity: .5;
          }
        }
        img { width: 600px; }
      }
    }
  }

  &-pikopiko {
    width: 800px;
    @media screen and (max-width: 820px) {
      width: 640px;
    }
    @media screen and (max-width: 640px) {
      width: 320px;
    }
    margin: 0 auto;
    h2 {
      border-left: 10px solid $aqua;
      border-bottom: 2px solid $aqua;
      padding-left: 10px;
      &:before {
        padding-right: 20px;
      }
    }
    h3 {
      border-left: 5px solid $aqua;
      padding-left: 5px;
      margin: 20px 0;
    }
    input {
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 380px;
      padding: 4px 8px;
      @media screen and (max-width: 640px) {
        width: 320px;
      }
    }
    button {
      padding: 4px 8px;
    }
    &-description {
      &-content {
        text-align: center;
        img {
          width: 400px;
          @media screen and (max-width: 820px) {
            width: 320px;
          }
          @media screen and (max-width: 640px) {
            width: 320px;
          }
        }
      }
    }
    &-safe {
      &-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        &-content {
          padding: 20px;
          border: 4px solid $aqua;
          border-radius: 8px;
          background-color: #fff;
          width: 740px;
          height: 310px;
          @media screen and (max-width: 780px) {
            width: 660px;
            height: 370px;
          }
          @media screen and (max-width: 640px) {
            width: 300px;
            height: 560px;
          }
          &-h2 {
            margin: 20px;
          }
          p { margin: 10px 0; }
          &-close {
            margin: 20px;
            text-align: center;
          }
        }
      }
    }

    &-input {
      display: flex;
      @media screen and (max-width: 820px) {
        display: block;
      }
      justify-content: space-around;
      &-field {
        margin-right: 40px;
        ul,li {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
        li {
          margin: 10px 0;
        }
      }
      &-candidate {
        &-input {
          margin: 10px 0;
        }
        section {
          margin: 10px 0;
        }
        &-register {
          button + button {
            margin-left: 20px;
            padding: 4px 0;
            @media screen and (max-width: 640px) {
              margin: 20px;
              margin-left: 0;
            }
            label {
              padding: 8px;
            }
          }
          &-upload-input { display: none; }
        }
      }
      &-register-btn {
        margin-right: 20px;
      }
      &-deleted {
        margin-left: 5px;
        animation: fade-in-out 10s ease-in-out 0s 1 none;
      }
      &-clear-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        &-content {
          border: 4px solid #dd0;
          border-radius: 8px;
          background-color: #fff;
          width: 540px;
          height: 320px;
          text-align: center;
          @media screen and (max-width: 640px) {
            width: 300px;
            height: 410px;
          }
          &-h2,
          &-description {
            margin: 20px;
          }
          &-dl {
            margin: 20px;
          }
          &-clear {
            color: #f00;
            border-color: #f00;
            border-radius: 4px;
            background-color: #fef;
          }
          button + button {
            margin-left: 40px;
            @media screen and (max-width: 640px) {
              margin-left: 0;
              margin: 20px;
            }
          }
        }
      }
    }
    &-preview {
      margin: 10px 0;
      &-section {
        text-align: center;
      }
      &-img {
        max-height: 200px;
      }
      &-size {
        input {
          width: 200px;
          margin-right: 5px;
          text-align: right;
        }
      }
    }
    &-code {
      margin-top: 30px;
      &-h {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-clipboard {
        text-align: right;
        &-copied {
          margin-right: 10px;
          animation: fade-in-out 10s ease-in-out 0s 1 none;
        }
      }
      &-edit {
        text-align: right;
        button + button {
          margin-left: 10px;
        }
      }
      &-textarea {
        margin: 10px 0;
        border: 2px solid #ccc;
        border-radius: 6px;
        padding: 10px;
        width: 780px;
        height: 720px;
        resize: vertical;
        background-color: #eee;
        @media screen and (max-width: 820px) {
          width: 640px;
        }
        @media screen and (max-width: 640px) {
          width: 320px;
        }
        &.is-edit {
          background-color: #fff;
        }
      }
    }
    a {
      &:hover {
        img {
          opacity: .5;
        }
      }
    }

    &-back-button {
      text-align: center;
      margin: 50px 0 0;
    }
  }
}

@keyframes fade-in-out {
  0% { opacity: 1; }
  30% { opacity: 0; }
  90% { opacity: 0; }
  100% { opacity: 1; }
}
